<template>
  <v-dialog
    v-model="dialogDeleteTempo"
    @click:outside="$emit('update:dialogDeleteTempo', false)"
    @keydown.esc="$emit('update:dialogDeleteTempo', false)"
    max-width="450px"
  >
    <v-card>
      <v-card-title class="px-4 pt-8 pb-4 text-subtitle-1 justify-center">
        Tem certeza que deseja deletar esse registro ?
      </v-card-title>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogDeleteTempo', false)"> voltar</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="error" @click="deletarTempo(item_id)"> Deletar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteAtividadeTempo } from "@/api/gestor/atividades_clientes.js";
export default {
  name: "DeleteHistoricoTempo",

  props: {
    dialogDeleteTempo: {
      type: Boolean,
      default: false,
    },
    item_id: {
      type: Number,
    },
  },
  data() {
    return {};
  },

  methods: {
    deletarTempo(id) {
      this.loading = true;
      deleteAtividadeTempo(id)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("fetch-historico");
            this.$emit("update:dialogDeleteTempo", false);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
